import { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { db } from "./firebase/firebaseConfig"; // Adjust the import path as necessary
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  addDoc,
  collection,
  setDoc,
} from "firebase/firestore";
import x from "./images/x.png";
import liar from "./images/logo-liar-no-text.png";
import twitter from "./images/twitter.png";
import whatsapp from "./images/whatsapp.png";
import telegram from "./images/telegram.png";
import instagram from "./images/instagram.png";
import facebook from "./images/facebook.png";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useLongPress } from "use-long-press";

const LETTER_TYPES = {
  CORRECT: 3,
  MISPLACED: 2,
  UNDEFINED: 0,
  WRONG: 1,
};

const LETTER_VALUES_TO_TYPES = {
  3: LETTER_TYPES.CORRECT,
  2: LETTER_TYPES.MISPLACED,
  0: LETTER_TYPES.UNDEFINED,
  1: LETTER_TYPES.WRONG,
};

const INDICATOR_CLASSES = {
  3: "correct",
  2: "misplaced",
  0: "undefined",
  1: "wrong",
};

function App() {
  const helmetContext = {};
  const [isSetting, setIsSetting] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [remainingTime, setRemainingTime] = useState("");
  const [todaysWord, setTodaysWord] = useState("");
  const [currentRow, setCurrentRow] = useState(0);
  const [currentCell, setCurrentCell] = useState(0);
  const [solveSeed, setSolveSeed] = useState(0);
  const [realSolvers, setRealSolvers] = useState(0);
  const [solverCount, setSolverCount] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isWin, setIsWin] = useState(false);
  const [showLosePopup, setShowLosePopup] = useState(false);
  const [isKeyboardDisabled, setIsKeyboardDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [flags, setFlags] = useState([-1, -1, -1, -1, -1, -1, -1, -1, -1, -1]);
  const [sures, setSures] = useState([[], [], [], [], [], [], [], [], [], []]);
  const [letterSures, setLetterSures] = useState([]);
  const [lies, setLies] = useState([
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
    { index: 0, type: LETTER_TYPES["UNDEFINED"] },
  ]);
  const [indicators, setIndicators] = useState([
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0],
  ]);
  const [letterIndicators, setLetterIndicators] = useState({
    א: [0, 0, 0, 0],
    ב: [0, 0, 0, 0],
    ג: [0, 0, 0, 0],
    ד: [0, 0, 0, 0],
    ה: [0, 0, 0, 0],
    ו: [0, 0, 0, 0],
    ז: [0, 0, 0, 0],
    ח: [0, 0, 0, 0],
    ט: [0, 0, 0, 0],
    י: [0, 0, 0, 0],
    כ: [0, 0, 0, 0],
    ל: [0, 0, 0, 0],
    מ: [0, 0, 0, 0],
    נ: [0, 0, 0, 0],
    ס: [0, 0, 0, 0],
    ע: [0, 0, 0, 0],
    פ: [0, 0, 0, 0],
    צ: [0, 0, 0, 0],
    ק: [0, 0, 0, 0],
    ר: [0, 0, 0, 0],
    ש: [0, 0, 0, 0],
    ת: [0, 0, 0, 0],
    ך: [0, 0, 0, 0],
    ם: [0, 0, 0, 0],
    ן: [0, 0, 0, 0],
    ף: [0, 0, 0, 0],
    ץ: [0, 0, 0, 0],
  });
  const [guesses, setGuesses] = useState([
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
    ["", "", "", "", ""],
  ]);

  useEffect(() => {
    const initGame = async () => {
      try {
        const isNewSession = await handleSession();

        if (isNewSession) {
          initNewGame();
        }
        await initTodaysWord();
        await initStats();
      } catch (err) {
        setError("Failed to fetch today's word.");
        console.error(err);
      } finally {
        setLoading(false);
      }

      async function initStats() {
        const statsRef2 = doc(db, "stats", "current");
        const statsSnap2 = await getDoc(statsRef2);
        setSolveSeed(statsSnap2.data().solve_seed);
        setRealSolvers(statsSnap2.data().real_solvers);
      }

      async function initTodaysWord() {
        const todaysWordRef = doc(db, "todays_word", "current");
        const todaysWordSnap = await getDoc(todaysWordRef);
        if (todaysWordSnap.exists()) {
          const data = todaysWordSnap.data();
          setTodaysWord(data.word);
        } else {
          setError("No word found for today.");
        }
      }

      async function initNewGame() {
        const todaysWordRef = doc(db, "todays_word", "current");
        const todaysWordSnap = await getDoc(todaysWordRef);
        const data = todaysWordSnap.data();
        setGuesses((guesses) => {
          guesses[0].splice(0, guesses[0].length, ...data.hint.split(""));
          return [...guesses];
        });
        setLies((lies) => {
          lies[0] = { index: data.lie.index, type: data.lie.type };
          return lies;
        });
      }

      async function handleSession() {
        let sessionIdTmp = document.cookie
          .split("; ")
          .find((row) => row.startsWith("sessionId="))
          ?.split("=")[1];

        let newSession = false;
        if (!sessionIdTmp) {
          const data = {
            guesses: btoa(
              unescape(encodeURIComponent(JSON.stringify(guesses)))
            ),
            indicators: btoa(
              unescape(encodeURIComponent(JSON.stringify(indicators)))
            ),
            flags: btoa(unescape(encodeURIComponent(JSON.stringify(flags)))),
            lies: btoa(unescape(encodeURIComponent(JSON.stringify(lies)))),
            currentRow: currentRow,
            date: new Date().toISOString().slice(0, 10),
          };
          const docRef = await addDoc(collection(db, "sessions"), data);
          sessionIdTmp = docRef.id;
          document.cookie = `sessionId=${sessionIdTmp}; path=/`;
          newSession = true;
        } else {
          const sessionDocRef = doc(db, "sessions", sessionIdTmp);
          const sessionSnap = await getDoc(sessionDocRef);
          if (!sessionSnap.exists()) {
            const data = {
              guesses: btoa(
                unescape(encodeURIComponent(JSON.stringify(guesses)))
              ),
              indicators: btoa(
                unescape(encodeURIComponent(JSON.stringify(indicators)))
              ),
              flags: btoa(unescape(encodeURIComponent(JSON.stringify(flags)))),
              lies: btoa(unescape(encodeURIComponent(JSON.stringify(lies)))),
              currentRow: currentRow,
              date: new Date().toISOString().slice(0, 10),
            };
            await setDoc(sessionDocRef, data);
            newSession = true;
          } else if (
            sessionSnap.data().date !== new Date().toISOString().slice(0, 10)
          ) {
            const data = {
              guesses: btoa(
                unescape(encodeURIComponent(JSON.stringify(guesses)))
              ),
              indicators: btoa(
                unescape(encodeURIComponent(JSON.stringify(indicators)))
              ),
              flags: btoa(unescape(encodeURIComponent(JSON.stringify(flags)))),
              lies: btoa(unescape(encodeURIComponent(JSON.stringify(lies)))),
              currentRow: currentRow,
              date: new Date().toISOString().slice(0, 10),
            };
            await updateDoc(sessionDocRef, data);
            newSession = true;
          }
        }

        setSessionId(sessionIdTmp);
        try {
          await getDataFromFirebase(sessionIdTmp);
        } catch (e) {
          console.log(e);
        }
        return newSession;
      }
    };

    initGame();
  }, []);

  useEffect(() => {
    if (!loading && !isWin && currentRow < 1) {
      enterWord();
    }
  }, [loading]);

  useEffect(() => {
    if (guesses[0][0] != "" && indicators[0][0] != -1 && isSetting) {
      setIsSetting(false);
      for (let i = 0; i < guesses.length; i++) {
        const word = guesses[i].join("");
        if (word == "") break;
        changeLetterIndicators(indicators[i], word, flags[i]);
      }
    }
  }, [flags, indicators[0][0], guesses[0][0]]);

  useEffect(() => {
    if (!loading) {
      saveToFirebase();
    }
  }, [indicators]);

  const saveToFirebase = async () => {
    const docRef = doc(db, "sessions", sessionId);
    await updateDoc(docRef, {
      guesses: btoa(unescape(encodeURIComponent(JSON.stringify(guesses)))),
      indicators: btoa(
        unescape(encodeURIComponent(JSON.stringify(indicators)))
      ),
      flags: btoa(unescape(encodeURIComponent(JSON.stringify(flags)))),
      lies: btoa(unescape(encodeURIComponent(JSON.stringify(lies)))),
      sures: btoa(unescape(encodeURIComponent(JSON.stringify(sures)))),
      letterSures: btoa(
        unescape(encodeURIComponent(JSON.stringify(letterSures)))
      ),
      currentRow: currentRow,
      date: new Date().toISOString().slice(0, 10),
    });
  };

  const getDataFromFirebase = async (sessionId) => {
    const docRef = doc(db, "sessions", sessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      const guessesDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.guesses)))
      );
      const indicatorsDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.indicators)))
      );
      const flagsDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.flags)))
      );
      const liesDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.lies)))
      );
      const suresDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.sures)))
      );
      const letterSuresDecoded = JSON.parse(
        decodeURIComponent(escape(atob(data.letterSures)))
      );
      setSures(suresDecoded);
      setLetterSures(letterSuresDecoded);
      setGuesses(guessesDecoded);
      setIndicators(indicatorsDecoded);
      setFlags(flagsDecoded);
      setLies(liesDecoded);
      setCurrentRow(data.currentRow);
      if (data.solverCount) {
        setSolverCount(data.solverCount);
        setIsWin(true);
        setIsKeyboardDisabled(true);
      }
      if (data.isLose == true) {
        setShowLosePopup(true);
        setIsKeyboardDisabled(true);
      }
    }
  };

  // const enterWord = async () => {
  //   if (isWin) return;
  //   const guessedWord = guesses[currentRow].join("");

  //   const wordExists = await checkWordExists(guessedWord);
  //   if (!wordExists) {
  //     setError("מילה לא קיימת במאגר");
  //     return;
  //   }
  //   let indicatorsTmp = [...indicators];
  //   let lieIndex = Math.floor(Math.random() * 5);
  //   if (guessedWord === todaysWord) {
  //     // Win
  //     indicatorsTmp[currentRow].fill(LETTER_TYPES["CORRECT"]);
  //     increaseSolvers();
  //     setRealSolvers(realSolvers + 1);
  //     setIsWin(true);
  //     const solver = calculateSolveCount();
  //     setSolverCount(solver);
  //     const docRef = doc(db, "sessions", sessionId);
  //     await updateDoc(docRef, {
  //       solverCount: solver,
  //     });
  //   } else {
  //     // Check each letter
  //     guessedWord.split("").forEach((letter, index) => {
  //       if (currentRow == 0 && lies[currentRow].index == index) {
  //         indicatorsTmp[currentRow][index] = lies[currentRow].type;
  //       } else if (todaysWord[index] === letter) {
  //         // Correct letter
  //         indicatorsTmp[currentRow][index] = LETTER_TYPES["CORRECT"];
  //       } else if (todaysWord.includes(letter)) {
  //         // Misplaced letter
  //         indicatorsTmp[currentRow][index] = LETTER_TYPES["MISPLACED"];
  //       } else {
  //         // Wrong letter
  //         indicatorsTmp[currentRow][index] = LETTER_TYPES["WRONG"];
  //       }

  //       if (currentRow != 0 && lieIndex == index) {
  //         let possibleValues = [
  //           LETTER_TYPES["CORRECT"],
  //           LETTER_TYPES["MISPLACED"],
  //           LETTER_TYPES["WRONG"],
  //         ];
  //         possibleValues = possibleValues.filter(
  //           (value) => value != indicatorsTmp[currentRow][index]
  //         );
  //         indicatorsTmp[currentRow][index] =
  //           possibleValues[Math.floor(Math.random() * possibleValues.length)];
  //       }
  //       changeLetterIndicator(
  //         guessedWord[index],
  //         indicatorsTmp[currentRow][index]
  //       );
  //     });
  //     if (currentRow == 8) setError("המשחק נגמר");
  //     setCurrentRow(currentRow + 1);
  //     setCurrentCell(0);
  //   }
  //   setIndicators(indicatorsTmp);
  // };

  const enterWord = async () => {
    setError(null);
    if (isKeyboardDisabled) return;
    const guessedWord = guesses[currentRow].join("");

    const wordExists = await checkWordExists(guessedWord);
    if (!wordExists) {
      setError('המילה "' + guessedWord + '" לא קיימת במאגר');
      return;
    }

    let indicatorsTmp = [...indicators];

    if (guessedWord === todaysWord) {
      // Win
      indicatorsTmp[currentRow].fill(LETTER_TYPES["CORRECT"]);
      increaseSolvers();
      setRealSolvers(realSolvers + 1);
      setIsWin(true);
      setIsKeyboardDisabled(true);
      const solver = calculateSolveCount();
      setSolverCount(solver);
      const docRef = doc(db, "sessions", sessionId);
      await updateDoc(docRef, {
        solverCount: solver,
      });
      return;
    }
    let todaysWordLettersCount = {};
    let alreadyProcessed = new Array(5).fill(false);

    // First, count the occurrences of each letter in todaysWord
    todaysWord.split("").forEach((letter) => {
      todaysWordLettersCount[letter] =
        (todaysWordLettersCount[letter] || 0) + 1;
    });

    // Temporarily mark correct letters to avoid double-counting
    guessedWord.split("").forEach((letter, index) => {
      if (letter === todaysWord[index]) {
        indicatorsTmp[currentRow][index] = LETTER_TYPES["CORRECT"];
        todaysWordLettersCount[letter]--;
        alreadyProcessed[index] = true;
      }
    });

    // Then, mark misplaced letters, ensuring not to double-count
    guessedWord.split("").forEach((letter, index) => {
      if (
        !alreadyProcessed[index] &&
        todaysWord.includes(letter) &&
        todaysWordLettersCount[letter] > 0
      ) {
        indicatorsTmp[currentRow][index] = LETTER_TYPES["MISPLACED"];
        todaysWordLettersCount[letter]--;
      } else if (!alreadyProcessed[index]) {
        indicatorsTmp[currentRow][index] = LETTER_TYPES["WRONG"];
      }
    });

    if (currentRow == 0) {
      indicatorsTmp[currentRow][lies[0].index] = lies[0].type;
    } else {
      // Choose a random index for the lie, ensuring it does not overwrite a correct letter
      let lieIndex = Math.floor(Math.random() * 5);
      console.log(
        "lie: " +
          lieIndex +
          ", indicator: " +
          indicatorsTmp[currentRow][lieIndex]
      );
      // Replace the chosen indicator with a lie (simplified logic for choosing the lie type)
      // Ensure that this operation respects the game's rules for lies
      let possibleValues = [
        LETTER_TYPES["CORRECT"],
        LETTER_TYPES["MISPLACED"],
        LETTER_TYPES["WRONG"],
      ];
      possibleValues = possibleValues.filter(
        (value) => value !== indicatorsTmp[currentRow][lieIndex]
      );
      indicatorsTmp[currentRow][lieIndex] =
        possibleValues[Math.floor(Math.random() * possibleValues.length)];
    }

    // Handle win condition
    if (guessedWord === todaysWord) {
      setIsWin(true);
      setIsKeyboardDisabled(true);
      // Additional win logic (updating solver count, etc.) goes here
    } else {
      // Prepare for the next guess
      if (currentRow == 9) {
        setShowLosePopup(true);
        setIsKeyboardDisabled(true);
        const docRef = doc(db, "sessions", sessionId);
        await updateDoc(docRef, {
          isLose: true,
        });
      }
      setCurrentRow(currentRow + 1);
      setCurrentCell(0);
    }

    // Update indicators on the UI
    changeLetterIndicators(indicatorsTmp[currentRow], guessedWord);
    setIndicators([...indicatorsTmp]);
  };

  const flagAsLie = (e, letter, row, cell) => {
    e.preventDefault();
    if (row >= currentRow) return;
    const flagsTmp = [...flags];
    const letterSuresTmp = [...letterSures];
    const suresTmp = [...sures];
    const letterIndicatorsTmp = { ...letterIndicators };
    if (flagsTmp[row] == cell) {
      if (sures[row].includes(cell)) {
        letterSuresTmp.push(letter);
        setLetterSures(letterSuresTmp);
      }
      flagsTmp[row] = -1;
      letterIndicatorsTmp[letter][indicators[row][cell]]++;
    } else {
      if (letterSures.includes(letter)) {
        var index = letterSures.indexOf(letter);
        letterSuresTmp.splice(index, 1);
        setLetterSures(letterSuresTmp);
      }
      if (flagsTmp[row] != -1) {
        letterIndicatorsTmp[guesses[row][flagsTmp[row]]][
          indicators[row][flagsTmp[row]]
        ]++;
      }
      flagsTmp[row] = cell;
      if (letterIndicators[letter][indicators[row][cell]] > 0)
        letterIndicatorsTmp[letter][indicators[row][cell]]--;
    }
    setLetterIndicators(letterIndicatorsTmp);
    setFlags(flagsTmp);
    const docRef = doc(db, "sessions", sessionId);
    updateDoc(docRef, {
      flags: btoa(unescape(encodeURIComponent(JSON.stringify(flagsTmp)))),
      letterSures: btoa(
        unescape(encodeURIComponent(JSON.stringify(letterSuresTmp)))
      ),
      sures: btoa(unescape(encodeURIComponent(JSON.stringify(suresTmp)))),
    });
  };

  const changeLetterSure = (e, letter, row, cell) => {
    e.preventDefault();
    if (row >= currentRow) return;
    const isMarkedAsLie = flags[row] === cell;
    const isMarkedAsSure = sures[row].includes(cell);
    const suresTmp = [...sures];
    const letterSuresTmp = [...letterSures];
    if (!isMarkedAsSure) {
      suresTmp[row].push(cell);
      setSures(suresTmp);
      if (!isMarkedAsLie) {
        letterSuresTmp.push(letter);
        setLetterSures(letterSuresTmp);
      }
    } else {
      const suresTmp = [...sures];
      var index = suresTmp[row].indexOf(cell);
      if (index > -1) suresTmp[row].splice(index, 1);
      setSures(suresTmp);
      var index = letterSuresTmp.indexOf(letter);
      if (index > -1) letterSuresTmp.splice(index, 1);
      setLetterSures(letterSuresTmp);
    }
    const docRef = doc(db, "sessions", sessionId);
    updateDoc(docRef, {
      sures: btoa(unescape(encodeURIComponent(JSON.stringify(suresTmp)))),
      letterSures: btoa(
        unescape(encodeURIComponent(JSON.stringify(letterSuresTmp)))
      ),
    });
  };
  const changeLetterSureWrapper = useLongPress((event, { context }) => {
    event.stopPropagation();
    const row = context.row;
    const cell = context.cell;
    if (row >= currentRow) return;
    const letter = context.letter;
    changeLetterSure(event, letter, row, cell);
  });

  const isLetterFlagged = (letter) => {
    for (var i = 0; i < flags.length; i++) {
      if (flags[i] == letter) {
        return true;
      }
    }
    return false;
  };

  const Grid = () => {
    return (
      <div className="grid">
        {guesses.map((guessRow, rowIndex) => (
          <div className="row" key={rowIndex}>
            {guessRow.map((letter, letterIndex) => (
              <div
                className={[
                  "cell",
                  `cell-${
                    INDICATOR_CLASSES[indicators[rowIndex][letterIndex]]
                  }`,
                  sures[rowIndex].includes(letterIndex)
                    ? flags[rowIndex] == letterIndex
                      ? "cell-flag-sure"
                      : "cell-sure"
                    : "",
                ].join(" ")}
                key={letterIndex}
                {...changeLetterSureWrapper({
                  row: rowIndex,
                  cell: letterIndex,
                  letter: letter,
                })}
                onClick={(e) => flagAsLie(e, letter, rowIndex, letterIndex)}
                onContextMenu={(e) =>
                  changeLetterSure(e, letter, rowIndex, letterIndex)
                }
              >
                <img
                  src={x}
                  className={[
                    "x",
                    "x-corner",
                    flags[rowIndex] !== letterIndex ? "hidden" : "",
                  ].join(" ")}
                />
                {letter} {/* This will be an empty string if not yet guessed */}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const Keyboard = ({ onKeyPress }) => {
    // Define the keys for each row on the keyboard
    const rows = [
      ["פ", "ם", "ן", "ו", "ט", "א", "ר", "ק"],
      ["ף", "ך", "ל", "ח", "י", "ע", "כ", "ג", "ד", "ש"],
      ["ץ", "ת", "צ", "מ", "נ", "ה", "ב", "ס", "ז"],
    ];
    return (
      <div className="keyboard">
        {rows.map((row, index) => (
          <div key={index} className="keyboard-row">
            {row.map((key) => (
              <button
                key={key}
                className={[
                  "key",
                  "letter",
                  `key-${INDICATOR_CLASSES[getLetterIndicator(key)]}`,
                  letterSures.includes(key) && !isLetterFlagged(key)
                    ? "key-sure"
                    : "",
                ].join(" ")}
                onClick={() => onKeyPress(key)}
              >
                {key}
              </button>
            ))}
          </div>
        ))}
        <div className="keyboard-row">
          <button className="key backspace" onClick={backspace}>
            ⌦
          </button>
          <button className="key enter" onClick={enterWord}>
            ENTER
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key.toLowerCase();
      const englishToHebrewMapping = {
        a: "ש",
        b: "נ",
        c: "ב",
        d: "ג",
        e: "ק",
        f: "כ",
        g: "ע",
        h: "י",
        i: "ן",
        j: "ח",
        k: "ל",
        l: "ך",
        m: "צ",
        n: "מ",
        o: "ם",
        p: "פ",
        q: "/",
        r: "ר",
        s: "ד",
        t: "א",
        u: "ו",
        v: "ה",
        w: "'",
        x: "ס",
        y: "ט",
        z: "ז",
        ",": "ת",
        ".": "ץ",
      };
      const hebrewLetter = englishToHebrewMapping.hasOwnProperty(key)
        ? englishToHebrewMapping[key]
        : key;
      if (/[\u0590-\u05FF]/.test(hebrewLetter)) {
        chooseLetter(hebrewLetter);
      } else if (key === "backspace") {
        backspace();
      } else if (key === "enter") {
        enterWord();
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentCell, currentRow, guesses]); // Add dependencies here

  const chooseLetter = (letter) => {
    if (isKeyboardDisabled) return;
    const guessesTmp = [...guesses];
    if (currentCell < 5 && currentRow < 10) {
      guessesTmp[currentRow][currentCell] = letter;
      setCurrentCell(currentCell + 1);
    }
    setGuesses(guessesTmp);
  };

  const backspace = () => {
    setError(null);
    if (isKeyboardDisabled) return;
    if (currentCell > 0) {
      const guessesTmp = [...guesses];
      guessesTmp[currentRow][currentCell - 1] = "";
      setCurrentCell(currentCell - 1);
    }
  };

  const increaseSolvers = async () => {
    const docRef = doc(db, "stats", "current");
    await updateDoc(docRef, {
      real_solvers: increment(1),
    });
  };

  const checkWordExists = async (word) => {
    const response = await fetch("/wordlist.txt");
    const wordlist = await response.text();
    return wordlist.split("\r\n").includes(word);
  };

  const changeLetterIndicator = (letter, type) => {
    const indicatorsTmp = { ...letterIndicators };
    indicatorsTmp[letter][type]++;
    setLetterIndicators(indicatorsTmp);
  };

  function changeLetterIndicators(indicatorsRow, guessedWord, flag = -1) {
    indicatorsRow.forEach((indicator, index) => {
      if (flag != index) changeLetterIndicator(guessedWord[index], indicator);
    });
  }

  const getLetterIndicator = (letter) => {
    const indicators = letterIndicators[letter];
    let index = 0;
    for (let i = indicators.length - 1; i >= 0; i--) {
      if (indicators[i] > 0) {
        index = i;
        break;
      }
    }
    return index;
  };

  const CountdownToMidnight = () => {
    const [remainingTime, setRemainingTime] = useState("");

    useEffect(() => {
      const timerId = setInterval(() => {
        const now = new Date();
        const timeZone = "Asia/Jerusalem";
        const nowInIsrael = new Date(now.toLocaleString("en-US", { timeZone }));
        const midnightInIsrael = new Date(nowInIsrael);
        midnightInIsrael.setDate(midnightInIsrael.getDate() + 1);
        midnightInIsrael.setHours(0, 0, 0, 0);

        const diff = midnightInIsrael - nowInIsrael;

        const hours = String(Math.floor(diff / 3600000)).padStart(2, "0");
        const minutes = String(Math.floor((diff % 3600000) / 60000)).padStart(
          2,
          "0"
        );
        const seconds = String(Math.floor((diff % 60000) / 1000)).padStart(
          2,
          "0"
        );

        setRemainingTime(`${hours}:${minutes}:${seconds}`);
      }, 1000);

      return () => clearInterval(timerId);
    }, []);

    return <div>{remainingTime}</div>;
  };

  const WinPopup = () => {
    const shareUrl = "https://hartetel.web.app/"; // Replace with your actual share URL
    const createAsciiGrid = () => {
      let asciiGrid = "";
      let excludeZeros = false;

      for (let i = 0; i < indicators.length; i++) {
        if (!excludeZeros) {
          let row = "";
          for (let j = 0; j < indicators[i].length; j++) {
            let cellValue = indicators[i][j];
            let cellEmoji = "";
            switch (cellValue) {
              case 1:
                cellEmoji = "⬛";
                break;
              case 2:
                cellEmoji = "🟨";
                break;
              case 3:
                cellEmoji = "🟩";
                break;
            }
            row += cellEmoji;
          }
          asciiGrid += row + "\n";
        }

        if (indicators[i].every((val) => val === 0)) {
          excludeZeros = true;
        }
      }

      return asciiGrid;
    };

    const defaultMessage = `אני הפותר ה-*${solverCount}* של המילה היומית ב-חרטטל!\nהצלחתי לפתור ב-*${
      currentRow + 1
    }* ניחושים!\n רוצה לנסות גם?\n${createAsciiGrid()}\n`;

    const handleShare = (platform) => {
      let shareLink = "";
      switch (platform) {
        case "whatsapp":
          shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${defaultMessage} ${shareUrl} \nכל יום מילה חדשה!`
          )}`;
          break;
        case "telegram":
          shareLink = `https://t.me/share/url?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(
            defaultMessage + "\nכל יום מילה חדשה!"
          )}`;
          break;
        case "twitter":
          shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(
            defaultMessage + "\nכל יום מילה חדשה!"
          )}`;
          break;
        default:
          break;
      }
      window.open(shareLink, "_blank");
    };

    return (
      <div className="winPopup-background">
        <div className="winPopup-container">
          <img
            src={x}
            className="closeButton"
            onClick={() => {
              setIsWin(false);
            }}
          />
          <h1>ניצחת!</h1>
          <p className="winPopup-text">
            המילה הייתה <p className="winPopup-todaysWord">{todaysWord}</p>
          </p>
          <p>
            אתה הפותר ה-<u>{solverCount}</u>
          </p>
          <p className="countdown">
            המילה הבאה בעוד
            {CountdownToMidnight()}
          </p>
          <div className="share-buttons">
            <p className="shareTitle">שתפו עם חברים</p>
            <img
              src={whatsapp}
              alt="Share on WhatsApp"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("whatsapp")}
            />
            <img
              src={telegram}
              alt="Share on Telegram"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("telegram")}
            />
            <img
              src={twitter}
              alt="Share on Twitter"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("twitter")}
            />
          </div>
        </div>
      </div>
    );
  };

  const LosePopup = () => {
    const shareUrl = "https://hartetel.web.app/"; // Replace with your actual share URL
    const createAsciiGrid = () => {
      let asciiGrid = "";
      let excludeZeros = false;

      for (let i = 0; i < indicators.length; i++) {
        if (!excludeZeros) {
          let row = "";
          for (let j = 0; j < indicators[i].length; j++) {
            let cellValue = indicators[i][j];
            let cellEmoji = "";
            switch (cellValue) {
              case 1:
                cellEmoji = "⬛";
                break;
              case 2:
                cellEmoji = "🟨";
                break;
              case 3:
                cellEmoji = "🟩";
                break;
            }
            row += cellEmoji;
          }
          asciiGrid += row + "\n";
        }

        if (indicators[i].every((val) => val === 0)) {
          excludeZeros = true;
        }
      }

      return asciiGrid;
    };

    const defaultMessage = `לא הצלחתי לפתור את המילה היומית ב-חרטטל :( אולי אתה תצליח?\n${createAsciiGrid()}\n`;

    const handleShare = (platform) => {
      let shareLink = "";
      switch (platform) {
        case "whatsapp":
          shareLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${defaultMessage} ${shareUrl}`
          )}`;
          break;
        case "telegram":
          shareLink = `https://t.me/share/url?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(defaultMessage)}`;
          break;
        case "twitter":
          shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            shareUrl
          )}&text=${encodeURIComponent(defaultMessage)}`;
          break;
        default:
          break;
      }
      window.open(shareLink, "_blank");
    };

    return (
      <div className="winPopup-background">
        <div className="winPopup-container">
          <img
            src={x}
            className="closeButton"
            onClick={() => {
              setShowLosePopup(false);
            }}
          />
          <h1>לא נורא</h1>
          <p className="winPopup-text">
            המילה הייתה <p className="winPopup-todaysWord">{todaysWord}</p>
          </p>
          <p>נסו שוב מחר</p>

          <p className="countdown">
            המילה הבאה בעוד
            {CountdownToMidnight()}
          </p>
          <div className="share-buttons">
            <p className="shareTitle">שתפו עם חברים</p>
            <img
              src={whatsapp}
              alt="Share on WhatsApp"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("whatsapp")}
            />
            <img
              src={telegram}
              alt="Share on Telegram"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("telegram")}
            />
            <img
              src={twitter}
              alt="Share on Twitter"
              className="shareButton" // Add the class name here
              onClick={() => handleShare("twitter")}
            />
          </div>
        </div>
      </div>
    );
  };

  const InstructionsPopup = () => {
    return (
      <div className="winPopup-background">
        <div className="winPopup-container">
          <img
            src={x}
            className="closeButton"
            onClick={() => setShowInstructions(false)}
          />
          <h1>הוראות</h1>
          <p className="winPopup-text">מצאו את המילה היומית</p>
          <p className="winPopup-text">לאחר כל ניחוש האותיות יקבלו צבעים</p>
          <p className="winPopup-text">אותיות נכונות במקום הנכון - 🟩</p>
          <p className="winPopup-text">אותיות נכונות במקום לא נכון - 🟨</p>
          <p className="winPopup-text">אותיות לא נכונות - ⬛</p>
          <p className="winPopup-todaysWord">אבל בכל שורה יש שקר!</p>
          <p className="winPopup-text">בהצלחה!</p>
          <p className="winPopup-text">
            <u>טיפ</u>
            <br /> לחצו על משבצת כדי לסמן אותה כ-שקר{" "}
            <img src={x} className="x" />
            <br />
            לחיצה ימנית או ארוכה כדי לסמן אות{" "}
            <span className="text-sure">בטוחה</span>
          </p>
        </div>
      </div>
    );
  };

  const calculateSolveCount = () => {
    const now = new Date();
    const minutes = now.getUTCMinutes() + now.getUTCHours() * 60;
    const solveCount = minutes + realSolvers;
    return solveCount;
  };

  const MainPage = () => {
    return (
      <div className="game-container">
        <p className="logo">
          <h1>חרטטל</h1>
          <img src={liar} className="logo-liar" />
        </p>
        <h7>היזהרו מחירטוטים.</h7>
        <h5>כל יום מילה חדשה!</h5>
        <a
          onClick={() => setShowInstructions(true)}
          className="instructions-link"
        >
          איך משחקים?
        </a>
        <Grid guesses={guesses} sures={sures} flags={flags} />
        <p className="clickX">
          <u>טיפ</u>
          <br /> לחצו על משבצת כדי לסמן אותה כ-שקר <img src={x} className="x" />
          <br />
          לחיצה ימנית או ארוכה כדי לסמן אות{" "}
          <span className="text-sure">בטוחה</span>
        </p>
        <p className="error">{error}</p>
        <Keyboard
          onKeyPress={chooseLetter}
          letterIndicators={letterIndicators}
          letterSures={letterSures}
        />
        {/* <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-8853630735736411"
          data-ad-slot="5891002146"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins> */}
        <div id="bthn" lang="en"></div>
        <ins
          className="adsbygoogle"
          style={{ display: "inline-block", width: "100%", height: "90px" }}
          data-ad-client="ca-pub-8853630735736411"
          data-ad-slot="5968388088"
        ></ins>
      </div>
    );
  };

  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8853630735736411"
          crossorigin="anonymous"
        ></script>
        <script>{"(adsbygoogle = window.adsbygoogle || []).push({});"}</script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-ZBD31J82E8"
        ></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              
              gtag('config', 'G-ZBD31J82E8');
            `}
        </script>
        <script>
          {`(function () {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src =
              "https://bringthemhomenow.net/1.1.0/hostages-ticker.js";
            script.setAttribute(
              "integrity",
              "sha384-DHuakkmS4DXvIW79Ttuqjvl95NepBRwfVGx6bmqBJVVwqsosq8hROrydHItKdsne"
            );
            script.setAttribute("crossorigin", "anonymous");
            document.getElementsByTagName("head")[0].appendChild(script);
          })()`}
        </script>
      </Helmet>

      <div className="App">
        <header className="App-header">{MainPage()}</header>
        {isWin ? <WinPopup /> : null}
        {showLosePopup ? <LosePopup /> : null}
        {showInstructions ? <InstructionsPopup /> : null}
      </div>
    </HelmetProvider>
  );
}

export default App;
