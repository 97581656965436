// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEw6f2Qgbjn9xrMj9z57cFTsXgZe4kAUM",
  authDomain: "hartetel.firebaseapp.com",
  projectId: "hartetel",
  storageBucket: "hartetel.appspot.com",
  messagingSenderId: "466854005798",
  appId: "1:466854005798:web:6fef84db6d569e84fbaae7",
  measurementId: "G-ZBD31J82E8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { app, db };
